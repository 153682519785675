import React from "react";

// @ts-expect-error TS(7016): Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from "styled-components";
import { saturate, lighten } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";

// @ts-expect-error TS(2307): Cannot find module '../../assets/image/png/l5-hero... Remove this comment to see the full error message
import imgHeroTab from "../../assets/image/png/l5-hero-image.png";

const SectionStyled = styled(Section)`
  background-image: ${({
  theme
}: any) => `radial-gradient(
    circle 961px at 49.94% 0%,
    ${lighten(0.114, saturate(0.0911, theme.colors.holzDarkGreen))} 0%,
    ${theme.colors.holzDarkGreen} 100%
  );`};
`;

const ImgRight = styled(Box)`
  position: relative;
  left: -100px;
  @media ${device.md} {
    position: absolute;
    top: 0;
    max-width: 100%;
    min-width: 100%;
    right: 0;
    left: auto;
    transform: translateX(25%);
  }
`;

const Hero = ({
  hero,
  slogan
}: any) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled className="position-relative">
        {/* <div className="pt-5"></div> */}
        <Container>
          <Row className="justify-content-center align-items-center position-relative">
            <Col md="5" className="order-md-2 position-static">
              {/* <ImgRight>
                <img
                  src={imgHeroTab}
                  alt=""
                  className="img-fluid"
                  data-aos="fade-left"
                  data-aos-duration="750"
                  data-aos-delay="1000"
                  data-aos-once="true"
                />
              </ImgRight> */}
            </Col>
            <Col md="7" className="order-md-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box py={[null, null, null, null]} pr={5} pt={[null, null]}>
                  <Title variant="hero" color="light">
                    {hero}
                    {/* Get your next <br className="d-none d-sm-block" />
                    landing page. */}
                  </Title>
                  <Text mb={4} color="light" opacity={0.7}>
                    {slogan}
                    {/* Create custom landing pages with Omega that convert more
                    visitors than any website. With lots of unique blocks, you
                    can easily build a page without coding. */}
                  </Text>
                  <div className="d-flex flex-column align-items-start pt-3">
                    <Button mb={2}>Start 14 Days Free Trial</Button>
                    <Text
                      variant="small"
                      fontSize={"14px"}
                      color="light"
                      opacity={0.7}
                    >
                      No credit card required
                    </Text>
                  </div>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
